import React from 'react';
import './AboutSection.css';

const AboutSection = () => {
  return (
    <section className="aboutsection">
      <div className="content">
        <h2 className="with-spacing">Empowering restaurant staff to recommend and sell high-margin beverages with AI</h2>
        <h3>Staffing Challenges, Less Profits</h3>
        <p>The restaurant game is tough. Razor thin margins and high turnover, among countless other things. Yet, increased beverage sales offer hope with higher profit potential. Undertrained staff make it extremely challenging to consistently recommend and upsell high-margin beverages with confidence. We solve this problem.</p>
      </div>

      <div className="content">
        <h3>Empowered Restaurant Teams</h3>
        <p>Our software transforms amateurs into wine, beer and spirit wizards. With Table4 AI, they&apos;re hooked on upselling. We help revenues soar and check averages increase. How do we do it? We employ the power of AI to craft the perfect beverage recommendations, tailored to your menu, cuisine, and your customers&apos; unique tastes.</p>
      </div>

      <div className="content">
        <h3>The Payoff</h3>
        <p>We promise you knowledge transfers in a flash. Consistency across your entire team with reduced training costs. Newbies hit the floor selling from day one, and veterans level up too. Our tech turns them into sales gurus.</p>
      </div>
    </section>
  );
}

export default AboutSection;

