import React from 'react';  //removed useState
import './LeadForm.css';

function LeadForm() {
    return (
    <section className="content-form" id="contact">
        <div className="text">
            <p className="black">We&apos;re excited to speak with you! Below, you&apos;ll find the contact information for one of our co-founders. They&apos;re real people, not automated email boxes. Let&apos;s have a conversation about how Table4 AI can transform your restaurant&apos;s beverage sales. Cheers to higher profits!</p>
            <p className="centered">Email us at&nbsp; <a href="mailto: adam@table4.ai" className="link">adam@table4.ai</a></p>
            <p className="centered">Or call us at&nbsp; <a href="tel:+17704215363" className="link">(770) 421-5363</a></p>
        </div>
    </section>
    )}

export default LeadForm;
