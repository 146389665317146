import React from 'react';
import AboutSection from './AboutSection';
import LeadForm from './LeadForm';
import './Home.css';
import logo from '../img/logo_xsmall.png';

const Home = () => {
  return (
    <div className="container homepage">
      <section className="section header">
        <img src={logo} alt="Logo" className="logo" />
        <ul className="menu">
          <li><a href="#contact">Contact</a></li>
          <li><a href="https://www.linkedin.com/company/table-4" target="_blank" rel="noopener noreferrer">Connect</a></li>
          {/* <li><a href="https://www.table4.ai/terms.html" target="_blank">Terms</a></li> */}
        </ul>
      </section>
      <AboutSection />
      <LeadForm />
      <div>
          <p className="copyright">© Table4 AI 2023 | All rights reserved</p>
          {/* <a href="#">Terms of service</a> | <a href="#">Legal stuff</a> */}
      </div>
    </div>
  );
}

export default Home;
