//BeverageSelectorBar.js is the selector to switch between different beverage recommendations within the RecommendationDrawer.  It is a child of RecommendationDrawer.js.

import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab } from '@mui/material';
import './BeverageSelectorBar.css';

function BeverageSelectorBar({ selectedBeverage, setSelectedBeverage }) {
    console.log('BeverageSelectorBar rendered');
    const handleTabChange = (event, newBeverage) => {
        console.log('Selected Beverage:', newBeverage);
        setSelectedBeverage(newBeverage);
    };

    return (
        <div className="beverage-selector-bar">
            <Tabs
                value={selectedBeverage}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label="Wine" value="wine" />
                <Tab label="Beer" value="beer" />
                <Tab label="Cocktail" value="cocktail" />
            </Tabs>
        </div>
    );
}

BeverageSelectorBar.propTypes = {
    selectedBeverage: PropTypes.string.isRequired,
    setSelectedBeverage: PropTypes.func.isRequired,
};

export default BeverageSelectorBar;