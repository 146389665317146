import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import BeverageRecommendation from './BeverageRecommendation';
import BeverageCharacteristics from './BeverageCharacteristics';
// import FunFact from './FunFact';
import BeverageSelectorBar from './BeverageSelectorBar';
import SelectedItemDetail from './SelectedItemDetail';
import './RecommendationDrawer.css';

const API_URL = process.env.REACT_APP_API_URL || '';

function RecommendationDrawer({ selectedItem, isOpen, setIsOpen }) {
    const [selectedBeverage, setSelectedBeverage] = useState('wine'); // defaulting to 'wine'
    const [beverageData, setBeverageData] = useState({
        wine: null,
        beer: null,
        cocktail: null,
    });
    const [isLoading, setIsLoading] = useState(true); // reintroduced loading state

    useEffect(() => {
        setIsLoading(true);

        if (selectedItem && selectedItem.name && selectedItem.restaurant_id) {
            const payload = {
                beverage_type: selectedBeverage,
                selected_item: selectedItem.name,
                selected_item_details: selectedItem.description,
                restaurant_id: selectedItem.restaurant_id
            };

            fetch(`${API_URL}/api/beverage-recommendation`, {
                method: 'POST', 
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                setBeverageData(prevData => ({
                    ...prevData,
                    [selectedBeverage]: data,
                }));
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error fetching beverage data:", error);
                setIsLoading(false);
            });
        } else {
            setIsLoading(false);
        }
    }, [selectedItem, selectedBeverage]);

    const currentBeverageData = beverageData[selectedBeverage] || {};

    return (
        <Drawer anchor="bottom" open={isOpen} onClose={() => setIsOpen(false)}>
            <div className="drawer-content">
                <div className="close-button" onClick={() => setIsOpen(false)}>
                    <svg height="24" width="24">
                        <line x1="0" y1="0" x2="12" y2="12" />
                        <line x1="12" y1="0" x2="0" y2="12" />
                    </svg>
                </div>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    <>
                        <SelectedItemDetail selectedItem={selectedItem} metadata={currentBeverageData.metadata} />
                        <BeverageRecommendation recommendation={currentBeverageData.recommendation} />
                        <BeverageCharacteristics characteristics={currentBeverageData.characteristics} />
                        {/* <FunFact funFact={currentBeverageData.funFact} /> */}
                        <BeverageSelectorBar 
                            selectedBeverage={selectedBeverage}
                            setSelectedBeverage={setSelectedBeverage}
                        />
                    </>
                )}
            </div>
        </Drawer>
    );
}

RecommendationDrawer.propTypes = {
    selectedItem: PropTypes.object,
    isOpen: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
};

export default RecommendationDrawer;
