import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home'; 
// import RestaurantList from './components/RestaurantList';
// import RestaurantMain from './components/RestaurantMain';
import RecommendationDrawer from './components/RecommendationDrawer';

function App() {
  const [recommendationsOpen, setRecommendationsOpen] = useState(false);

  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/restaurants" element={<RestaurantList />} />
          <Route
            path="/menu/:id"
            element={
              <RestaurantMain
                onOpenRecommendations={() => setRecommendationsOpen(true)}
              />
            }
          /> */}
        </Routes>
        {recommendationsOpen && (
          <RecommendationDrawer
            isOpen={recommendationsOpen}
            setIsOpen={setRecommendationsOpen}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
