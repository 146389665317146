import React from 'react';
import './BeverageRecommendation.css';

function BeverageRecommendation({ recommendation }) {
  console.log('BeverageRecommendation rendered');

  let sentences;

  if (recommendation) {
    sentences = recommendation.split('. ');
  } else {
    sentences = ['No recommendation available'];
  }

  return (
    <div className="recommendation-block">
      <div className="squiggly-top"></div>
      {/* Display the first sentence */}
      {sentences[0]}.
      {/* Line break */}
      <br />
      <br />
      {/* Display the remaining sentences */}
      {sentences.slice(1).join('. ')}
      <div className="squiggly-bottom"></div>
    </div>
  );
}

export default BeverageRecommendation;
