// Create a component that displays the details of a selected item and its pairing metadata.
// It should be slightly different for beer, wine and cocktails.

import React from 'react';
import PropTypes from 'prop-types';
import './SelectedItemDetail.css';

function SelectedItemDetail({ selectedItem, metadata }) {
    console.log('SelectedItemDetail rendered');
    if (!selectedItem || !metadata) return null;

    return (
        <div className="selected-item-section">
            <div className="metadata-template-text">WE RECOMMEND PAIRING THE</div>
            <div className="selected-item-name">{selectedItem.name}</div>
            <div className="selected-item-name">WITH</div>
            <div className="beverage-name">{metadata.name}</div>
            <div className="beverage-location">{metadata.description}</div>
        </div>
    );
}


SelectedItemDetail.propTypes = {
    selectedItem: PropTypes.object,
    metadata: PropTypes.object,
};

export default SelectedItemDetail;