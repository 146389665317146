

import React from 'react';
import './BeverageCharacteristics.css';

function BeverageCharacteristics({characteristics = {}}) {
    console.log('BeverageCharacteristics rendered');
    
    const attributeArray = characteristics.attributes;

    if (!Array.isArray(attributeArray)) {
        console.error('BeverageCharacteristics: characteristics.attributes must be an array');
        return null;
    }

    return (
        <div className="characteristics-container">
            {attributeArray.map((char, index) => (
                <div key={index} className="characteristic-item">
                    <div className="ellipse" style={{backgroundColor: char.circleColor}}>
                        <div className='characteristic-label'>{char.attribute}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default BeverageCharacteristics;
